$(document).ready(function() {

  var mql = window.matchMedia('all and (max-width: 991px)');
  if (mql.matches) {
    $('#sliderNav1').slick({
      slidesToShow: 15,
      slidesToScroll: 1,
      dots: false,
      focusOnSelect: true,
      rows: 3,
      slidesPerRow: 3,
      draggable: false,
      swipe: false,
      touchMove: false,
      verticalSwiping: false,
      asNavFor: '#slider1'
    });
  } else {
    $('#sliderNav1').slick({
      slidesToShow: 15,
      slidesToScroll: 1,
      dots: false,
      vertical: true,
      focusOnSelect: true,
      draggable: false,
      swipe: false,
      touchMove: false,
      verticalSwiping: false,
      asNavFor: '#slider1'
    });
  }

  $('#slider1').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    draggable: false,
    swipe: false,
    touchMove: false,
    verticalSwiping: false,
    arrows: true,
    prevArrow: '<button type="button" class="slick-prev sc-base-slider-prev btn"><svg width="23" height="31" viewBox="0 0 23 31" xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="xMinYMin"><use xlink:href="#img-slider-arrow"></use></svg></button>',
    nextArrow: '<button type="button" class="slick-next sc-base-slider-next btn"><svg width="23" height="31" viewBox="0 0 23 31" xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="xMinYMin"><use xlink:href="#img-slider-arrow"></use></svg></button>',
    asNavFor: '#sliderNav1'
  });

  $('#slider1').on('init reInit afterChange', function(event, slick, currentSlide) {

    var nrCurrentSlide = slick.currentSlide + 1;
    $('.sc-base-slider__count').html("<span class='sc-base-slider__num'>" + nrCurrentSlide + "</span>/" + slick.slideCount);
  });
});